import "./Share.scss";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";

const Share = () => {
  const { t } = useTranslation("global");
  const [file, setFile] = useState("");
  const [descr, setDescr] = useState("");
  const [err, setErr] = useState("");

  const { currentUser } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (newPost) => {
      return makeRequest.post("/posts", newPost);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
  });

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setErr("");
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      try {
        const compressedFile = await imageCompression(selectedFile, {
          maxSizeMB: 1,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        });

        if (compressedFile.size > 69000) {
          setErr(t("dataValidation.largeInput"));
          return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          setFile(reader.result);
        };

        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error(t("dataValidation.imgComErr"), error);
      }
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (descr.trim() !== "" || (file && file.startsWith("data:image/"))) {
      mutation.mutate({ descr, img: file });
      setDescr("");
      setFile("");
      setErr("");
    } else {
      setErr(t("dataValidation.noInput1"));
    }
  };

  return (
    <div
      className="share"
      style={{ backgroundColor: currentUser.user_color, textAlign: "center" }}
    >
      <div className="container">
        <div className="top">
          <div className="left">
            <input
              type="text"
              placeholder={`${t("forum.opin")}, ${currentUser.username} ?`}
              onChange={(e) => {
                setErr("");
                setDescr(e.target.value);
              }}
              value={descr}
            />
          </div>
          <div className="right">
            {file && (
              <img
                className="file"
                style={{ maxWidth: "255px", height: "auto" }}
                alt="Upload"
                src={file}
              />
            )}
          </div>
        </div>
        <br />
        <br />
        <span style={{ color: "DarkRed" }}>{err}</span>
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="file">
              <div className="item">
                <span>{t("forum.addImg")}</span>
              </div>
            </label>
          </div>
          <div className="right">
            <button onClick={handleClick}>{t("forum.share")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
