import Posts from "../../components/posts/Posts.jsx";
import Share from "../../components/share/Share.jsx";

const Forum = () => {
  return (
    <div className="home1">
      <br />
      <Share />
      <Posts />
      <br />
    </div>
  );
};

export default Forum;
